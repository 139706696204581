import type {
  CommClientConfiguration,
  ICommClient,
  VideoCallMember,
} from "@teladoc/ucs-client-react"
import { lazy } from "react"
import { importMicrofrontendFromServiceRegistry } from "@teladoc/service-discovery"
import { UiCommSessionEndedArgs } from "@teladoc/ucs-client-react/types/session/events"
import type {
  SessionConnectFailedEvent,
  SessionErrorEvent,
} from "@teladoc/ucs-client-react"

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type UcsModule = typeof import("@teladoc/ucs-client-react")

const importUCS = (): Promise<UcsModule> =>
  importMicrofrontendFromServiceRegistry("ucs-react-mfe", "/", {
    mfeUrlPrefix: window.config.CDN_MFE_PREFIX || "",
    serviceRegistryURL: window.config.SERVICE_REGISTRY_URL || "",
  })

const importSpecificExport = <K extends keyof UcsModule>(exportName: K) =>
  importUCS().then((m) => m[exportName])

export const VideocallLayout = lazy(() =>
  importSpecificExport("VideocallLayout").then((d) => ({ default: d }))
)

export const initUCS = (configuration: CommClientConfiguration) =>
  importSpecificExport("initUCS").then((init) => init(configuration))

export const getSharedCommClient = () =>
  importSpecificExport("getSharedCommClient").then((f) => f())

export const setRtcClient = (commClient: ICommClient) =>
  importSpecificExport("setRtcClient").then((setRtcClient) =>
    setRtcClient(commClient)
  )

export const getRtcClient = () =>
  importSpecificExport("getRtcClient").then((f) => f())

export const PreCallTest = lazy(() =>
  importSpecificExport("PreCallTest").then((d) => ({ default: d }))
)

// eslint-disable-next-line no-unused-vars
export const whenSessionEnded = (f: (args: UiCommSessionEndedArgs) => void) => {
  let unwatchSessionEnded: () => unknown = () => void 0
  const unwatch = () => {
    unwatchSessionEnded()
  }
  importSpecificExport("sessionEnded").then((sessionEnded) => {
    unwatchSessionEnded = sessionEnded.watch((uiCommSessionEndedArgs) =>
      f(uiCommSessionEndedArgs)
    )
  })
  return unwatch
}

export const whenSessionConnected = (f: () => void) => {
  let unwatchSessionConnected: () => unknown = () => void 0
  const unwatch = () => {
    unwatchSessionConnected()
  }
  importSpecificExport("sessionConnected").then((sessionConnected) => {
    unwatchSessionConnected = sessionConnected.watch(() => f())
  })
  return unwatch
}

export const whenSessionConnectFailed = (
  f: (event: SessionConnectFailedEvent) => void
) => {
  let unwatchSessionConnectFailed: () => unknown = () => void 0
  const unwatch = () => {
    unwatchSessionConnectFailed()
  }
  importSpecificExport("sessionConnectFailed").then((sessionConnectFailed) => {
    unwatchSessionConnectFailed = sessionConnectFailed.watch(f)
  })
  return unwatch
}

export const whenSessionError = (f: (event: SessionErrorEvent) => void) => {
  let unwatchSessionError: () => unknown = () => void 0
  const unwatch = () => {
    unwatchSessionError()
  }
  importSpecificExport("sessionError").then((sessionError) => {
    unwatchSessionError = sessionError.watch(f)
  })
  return unwatch
}

export const WaitingRoom = lazy(() =>
  importSpecificExport("WaitingRoom").then((d) => ({ default: d }))
)

export const UnsupportedWarning = lazy(() =>
  importSpecificExport("UnsupportedWarning").then((d) => ({ default: d }))
)

export const WarningBanner = lazy(() =>
  importSpecificExport("WarningBanner").then((d) => ({ default: d }))
)

export const isSupported = () =>
  importSpecificExport("isSupported").then((f) => f())

export const getControlButtonWhiteList = () =>
  importSpecificExport("getControlButtonWhiteList").then((f) => f())

export const setControlButtonWhiteList = (controls: string[]) =>
  importSpecificExport("setControlButtonWhiteList").then((f) => f(controls))

export const whenMembersUpdated = (f: (members: VideoCallMember[]) => void) => {
  let unwatchMembers: () => unknown = () => void 0
  const unwatch = () => {
    unwatchMembers()
  }
  importSpecificExport("membersStore").then((membersStore) => {
    unwatchMembers = membersStore.watch(f)
  })
  return unwatch
}

export const getMembersList = () =>
  importSpecificExport("membersStore").then((membersStore) =>
    membersStore.getState()
  )
