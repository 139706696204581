import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import type { UIProfile } from "../stores/startupConfiguration"
import { PreCheckError } from "../preCheckHandlers/PreCheckError"
import { preCallTestPreCheckHandler } from "../preCheckHandlers/preCallTestPreCheckHandler"
import { ErrorReason } from "../ErrorReason"
import { getUiProfile } from "../stores/startupConfiguration"

export type Page = "landing" | "welcome" | "precall" | "videocall" | "thankyou"

const shouldShowWelcomePage = (uiProfile: UIProfile) =>
  uiProfile.collect_user_name
const shouldShowPrecallPage = (uiProfile: UIProfile) =>
  uiProfile.offer_precall_test

type PreCheckHandler = {
  targetPage: Page
  handler: () => Promise<void>
}

const preCheckHandlers: PreCheckHandler[] = [
  { targetPage: "precall", handler: preCallTestPreCheckHandler },
]

const determineNextPage = (page: Page): Page => {
  const uiProfile = getUiProfile()
  let targetPage: Page = "videocall"
  if (page === "landing") {
    if (shouldShowWelcomePage(uiProfile)) {
      targetPage = "welcome"
    } else if (shouldShowPrecallPage(uiProfile)) {
      targetPage = "precall"
    }
  } else if (page === "welcome") {
    if (shouldShowPrecallPage(uiProfile)) {
      targetPage = "precall"
    }
  } else if (page === "videocall") {
    targetPage = "thankyou"
  }
  return targetPage
}

export const usePageNavigation = (currentPage: Page) => {
  const doPreCheck = useCallback(async () => {
    if (currentPage) {
      const nextPage = determineNextPage(currentPage)
      const handler = preCheckHandlers.find(
        (item) => item.targetPage === nextPage
      )
      if (handler) {
        try {
          await handler.handler()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
          return `/error/${
            error instanceof PreCheckError
              ? error.errorCode
              : ErrorReason.UNHANDLED
          }`
        }
      }
      return `/${nextPage}`
    }
  }, [currentPage])

  const navigate = useNavigate()

  const goToNextPage = async () => {
    const nextUrl = await doPreCheck()
    if (nextUrl) {
      navigate(nextUrl)
    }
  }

  const goToErrorPage = (reasonCode?: string) =>
    navigate(`/error/${reasonCode ? reasonCode : ErrorReason.UNHANDLED}`)

  const goToInvalidUrlPage = () => navigate("/invalidUrl")

  return { goToNextPage, goToErrorPage, goToInvalidUrlPage }
}
