import { useEffect, useState } from "react"
import { isSupported as checkIsSupported } from "../../ucs"
import { $startupConfigurationStore } from "../stores/startupConfiguration"
import { useStore } from "effector-react"

export const useUnsupportedWarning = () => {
  const startupConfiguration = useStore($startupConfigurationStore)
  const [isSupported, setIsSupported] = useState<boolean>()

  useEffect(() => {
    if (!startupConfiguration) return
    if (startupConfiguration.startup_options.skip_browser_check) {
      setIsSupported(true)
    } else {
      checkIsSupported().then(setIsSupported)
    }
  }, [startupConfiguration])

  return { isSupported }
}
